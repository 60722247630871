import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
export default function Filters({onChange, values, onClick, residential, incomeValue, onDownloadPdf}) {

    let disabled =React.useMemo(()=>{
        return !(values.from && values.to && values.residential.id.length>0 )
    },[values])

    return (
        <MDBox display="flex" alignItems="center" bgColor={'#fff'} p={2} mt={2} borderRadius={8}>
            <MDBox width="14rem" mr={2}>
                <Autocomplete
                    options={residential}
                    disableClearable
                    clearOnEscape
                    size="small"
                    renderInput={(params) => <MDInput {...params} placeholder={'Residencial'}/>}
                    onChange={(e, value) =>
                        onChange(e, {id: value.id, label: value.label, autocomplete: "residential"})
                    }
                    getOptionLabel={(option) => option.label || ''}
                />
            </MDBox>

            <MDBox width="14rem" mr={2}>
                <MDInput fullWidth type={'date'} name={'from'} size={'small'} onChange={onChange} />
            </MDBox>

            <MDBox width="14rem" mr={2}>
                <MDInput fullWidth type={'date'} name={'to'} size={'small'} onChange={onChange} />
            </MDBox>

            <MDBox width="10rem" mr={2}>
                <MDButton disabled={disabled} onClick={onClick} fullWidth color="secondary">
                    Filtrar
                </MDButton>
            </MDBox>

            <MDBox width="10rem">
                <MDButton disabled={incomeValue===0} onClick={onDownloadPdf} fullWidth color="error">
                    Descargar Pdf
                </MDButton>
            </MDBox>

        </MDBox>
    )
}
