export const SESSION = "SESSION";
export function success(payload) {
  return {
    type: SESSION,
    payload,
  };
}

export function postSession(payload) {
  return (dispatch) => {
    dispatch(success(payload));
  };
}
