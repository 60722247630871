import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Modal, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import {DateTime} from "luxon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

var formatter = new Intl.NumberFormat('es-do', {
  style: 'currency',
  currency: 'DOP',
});

export default function EditModal({
                                      open,
                                      handleClose,
                                      onCreate,
                                      model,
                                      onChange,
                                      apartments,
                                      residential,
                                      bank,

                                  }) {

    let isDisabled = React.useMemo(() => {
        return !(model.apartment && model.residential && model.bank && model.amount > 0 && model.amount <= model.apartment?.previousDueAmount )
    }, [model])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    AGREGAR NUEVO INGRESO
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.residential}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, ...value, autocomplete: "residential"})
                        }
                        options={residential}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Residencial" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>

                {apartments.length ? (
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={model.apartment}
                                onChange={(e, value) =>
                                    onChange(e, {id: value.id, label: value.label, ...value, autocomplete: "apartment"})
                                }
                                options={apartments}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <FormField {...params} label="Apartamento" InputLabelProps={{shrink: true}}/>
                                )}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                            />
                        </Grid>

                        <br/>

                        <Grid item xs={12}>
                            <Autocomplete
                                value={model.bank}
                                onChange={(e, value) =>
                                    onChange(e, {id: value.id, label: value.label, autocomplete: "bank"})
                                }
                                options={bank}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <FormField {...params} label="Via De Pago" InputLabelProps={{shrink: true}}/>
                                )}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                            />
                        </Grid>

                        <br/>

                        {model.apartment?.previousDueAmount > 0 && (
                            <div style={{
                                borderRadius: 4,
                                borderColor: '#c2c2c2',
                                borderWith: 0.5,
                                borderStyle: 'solid',
                                padding: 8
                            }}>
                                <b style={{fontSize: 16}}>Información de la deuda anterior</b>
                                <p style={{fontSize: 14}}>Monto: {formatter.format(model.apartment?.previousDueAmount)}</p>
                                <p style={{fontSize: 14}}>Fecha del último pago
                                    : {DateTime.fromFormat(model.apartment?.previousDueLastPayment, 'yyyy-mm-dd').toFormat('DDD')}</p>
                                {/*<p style={{fontSize: 14}}>Meses pendientes: {model.apartment?.previousDueMonths}</p>*/}
                            </div>
                        )}

                        <br/>

                        <Grid item xs={12}>
                            <FormField
                                value={model.amount}
                                max={4500}
                                error={model.amount > model.apartment?.previousDueAmount}
                                type={'number'}
                                name="amount"
                                onChange={onChange}
                                fontWeight="bold"
                                label="Monto"
                                placeholder="Monto"
                            />
                            {model.amount > model.apartment?.previousDueAmount && (
                                <label style={{fontSize: 16, color:'red'}}>El monto a pagar no puede ser mayor al monto de la deuda</label>
                            )}

                        </Grid>
                        <br/>

                        <Grid item xs={12}>
                            <FormField
                                value={model.comment}
                                name="comment"
                                onChange={onChange}
                                fontWeight="bold"
                                label="Comentario"
                                placeholder="pago Recibido"
                            />
                        </Grid>
                    </>

                ) : (
                    model.residential && (
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            No hay apartamento con deuda anterior en este residencial
                        </Typography>
                    )
                )}

                <br/>

                {apartments.length ? (
                    <MDButton disabled={isDisabled} style={{marginRight: 5}} color="success" onClick={onCreate}>
                        Crear
                    </MDButton>
                ) : null}
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
