import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import {paymentStatus, paymentStatusInput} from "../../../utilities/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function AddModal({
  open,
  handleClose,
  onCreate,
  model,
  onChange,
  provider,
  residential,
  bank,
}) {

  let isDisabled = React.useMemo(() => {
    return !(model.residential && model.provider && model.paymentStatus && model.bank && model.amount>0 && model.concept.length > 0)
  }, [model.residential, model.provider, model.paymentStatus, model.bank, model.amount, model.concept])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          AGREGAR NUEVO GASTO
        </Typography>
        <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.residential}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "residential" })
            }
            options={residential}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <FormField {...params} label="Residencial" InputLabelProps={{ shrink: true }} />
            )}
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
              );
            }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.provider}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "provider" })
            }
            options={provider}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <FormField {...params} label="Proveedor" InputLabelProps={{ shrink: true }} />
            )}
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
              );
            }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
              options={paymentStatusInput}
              value={model.paymentStatus}
              disableClearable
              clearOnEscape
              size="small"
              renderInput={(params) => <FormField {...params} label={'Estado de pago'} InputLabelProps={{ shrink: true }} />}
              onChange={(e, value) =>
                  onChange(e, {id: value.id, label: value.label, autocomplete: "paymentStatus"})
              }
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                );
              }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
              value={model.bank}
              onChange={(e, value) =>
                  onChange(e, { id: value.id, label: value.label, autocomplete: "bank" })
              }
              options={bank}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                  <FormField {...params} label="Via De Pago" InputLabelProps={{ shrink: true }} />
              )}
              disableClearable
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                );
              }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.concept}
            name="concept"
            onChange={onChange}
            fontWeight="bold"
            label="Concepto"
            placeholder="Pago Electricidad Comun"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.amount}
            name="amount"
            type={'number'}
            onChange={onChange}
            fontWeight="bold"
            label="Importe"
            placeholder="pago Recibido"
          />
        </Grid>
        <br />


        <MDButton disabled={isDisabled} style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Crear
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
