import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import AddModal from "./components/AddModal";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";

export default function Balance() {
    const {
        residential,
        balanceList,
        setModel,
        cAlert,
        setCAlert,
        onChange,
        onCreate,
        open,
        setOpen,
        model,
        loading,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                <DataTable
                    onClick={() => setOpen(true)}
                    canSearch
                    table={{
                        columns: [
                            {Header: "fecha", accessor: "createdAt"},
                            {Header: "residencial", accessor: "residential.label"},
                            {Header: "balance inicial", accessor: "balance"},
                        ],
                        rows: balanceList,
                    }}
                />
            </MDBox>
            <AddModal
                residential={residential}
                onChange={onChange}
                onCreate={onCreate}
                open={open}
                handleClose={() => setOpen(false)}
                model={model}
                setModel={setModel}
            />
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}
