import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {FormLabel, Modal, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import {paymentStatus} from "../../../utilities/constants";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

var formatter = new Intl.NumberFormat('es-do', {
    style: 'currency',
    currency: 'DOP',
});


export default function AddModal({
                                     open,
                                     handleClose,
                                     onCreate,
                                     model,
                                     onChange,
                                     apartments,
                                     residential,
                                     bank,
                                 }) {

    let isDisabled = React.useMemo(() => {
        return !(model.apartment && model.residential && model.bank && model.paymentStatus && model.gallonsQty > 0 && model.price > 0)
    }, [model])


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    AGREGAR CONSUMO DE GAS
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.residential}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, autocomplete: "residential"})
                        }
                        options={residential}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Residencial" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.apartment}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, ...value, autocomplete: "apartment"})
                        }
                        options={apartments}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Apartamento" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>

                <br/>

                <Grid item xs={12}>
                    <Autocomplete
                        value={model.bank}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, autocomplete: "bank"})
                        }
                        options={bank}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Via De Pago" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>

                <br/>
                <Grid item xs={12}>
                    <FormField
                        value={model.gallonsQty}
                        name="gallonsQty"
                        type={'number'}
                        onChange={onChange}
                        fontWeight="bold"
                        label="Consumo En Galones"
                        placeholder="4.5"
                    />
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <FormField
                        value={model.price}
                        name="price"
                        type={'number'}
                        onChange={onChange}
                        fontWeight="bold"
                        label="Precio Por Galon"
                        placeholder="115.4"
                    />
                </Grid>
                <br/>

                <Grid item xs={12}>
                    <FormLabel style={{fontSize: 14}}>Importe Total</FormLabel>
                    <h4 style={{color: '#000'}}>{model.gallonsQty > 0 && model.price > 0 ? formatter.format(model.gallonsQty * model.price) : 'RD$0.00'}</h4>
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.paymentStatus}
                        options={paymentStatus}
                        disableClearable
                        clearOnEscape
                        size="small"
                        renderInput={(params) => (
                            <FormField {...params} label="Estado de  pago" InputLabelProps={{shrink: true}}/>
                        )}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, autocomplete: "paymentStatus"})
                        }
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>

                <br/>
                <MDButton disabled={isDisabled} style={{marginRight: 5}} color="success" onClick={onCreate}>
                    Crear
                </MDButton>
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
