import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import React from "react";

function Header({logout}) {

    return (
        <Card id="profile">
            <MDBox p={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sx={{ml: "auto"}}>
                        <MDBox pb={3}>
                            <MDTypography variant="h5">Sesión</MDTypography>
                        </MDBox>
                        <MDBox
                            display="flex"
                            justifyContent={{md: "flex-start"}}
                            alignItems="center"
                            lineHeight={1}
                        >
                            <MDBox>
                                <MDButton size={'small'} onClick={logout} color="error">
                                    Cerrar sesión
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Header;
