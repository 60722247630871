import MDBox from "../../../components/MDBox";
import React from "react";
import Grid from "@mui/material/Grid";
import {FormLabel} from "@mui/material";
import MDButton from "../../../components/MDButton";
import {DateTime} from "luxon";

var formatter = new Intl.NumberFormat('es-do', {
    style: 'currency',
    currency: 'DOP',
});

export default function Summary({gasValue, previousDueValue, incomeValue,othersIncomeValue, expensesValue, balance, onClick, isBalance, lastValidation, loading}) {

    let from = lastValidation?DateTime.fromSeconds(lastValidation).toFormat('DDD, t'):'';
    let from2 = lastValidation?DateTime.fromSeconds(lastValidation).toRelative():'';
    return (

        <MDBox bgColor={'#fff'} borderRadius={8}   p={2} mt={2}>
            <b style={{fontSize:12, margin:0, padding:0}}>Leyenda: T = total</b>

            <MDBox display="flex" alignItems="center">
                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>T de ingresos brutos</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(incomeValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>T de deuda anterior</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(previousDueValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>T de gas</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(gasValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>Otros ingresos</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(othersIncomeValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>T de gastos</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(expensesValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>T de utilidades</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(incomeValue+ previousDueValue + gasValue + othersIncomeValue - expensesValue)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>Balance anterior</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(balance)}</h4>
                    </Grid>
                </MDBox>

                <MDBox width="14rem" mr={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>Balance actual</FormLabel>
                        <h4 style={{color: '#000'}}>{formatter.format(incomeValue + previousDueValue + gasValue + othersIncomeValue + Number(balance) - expensesValue)}</h4>
                    </Grid>
                </MDBox>

                {incomeValue > 0 ? (
                    <MDBox width="14rem">
                        <MDButton disabled={!isBalance} onClick={onClick} fullWidth color="success">
                            Validar
                        </MDButton>
                    </MDBox>
                ) : null}


                <br/>
                <br/>
            </MDBox>
            {!loading && incomeValue > 0 && !isBalance && (
                <MDBox mt={2}>
                    <Grid item xs={12}>
                        <h4 style={{color: 'red'}}>Debe agregar el balance inicial (balance anterior) al residencial para poder
                            continuar</h4>
                    </Grid>
                </MDBox>
            )}
            {lastValidation && (
                <MDBox mt={2}>
                    <Grid item xs={12}>
                        <FormLabel style={{fontSize: 14}}>Fecha ultima validación:</FormLabel>
                        <h4 style={{color: 'green'}}>{from}</h4>
                        <h5 style={{color: 'green'}}>{from2}</h5>
                    </Grid>
                </MDBox>
            )}
        </MDBox>

    )
}
