import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {billingDayData, modalStyle} from "../../../utilities/constants";

export default function AddModal({ open, handleClose, onCreate, model, onChange }) {

  let isDisabled =React.useMemo(()=>{
    return !(model.residential && model.address && model.residentialQty && model.maintenanceCost && model.billingDay)}, [model])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          AGREGAR RESIDENCIAL
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.residential}
            name="residential"
            onChange={onChange}
            fontWeight="bold"
            label="Nombre Del Residencial"
            placeholder="Res. Brisas de Gurabo"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.address}
            name="address"
            onChange={onChange}
            fontWeight="bold"
            label="Dirección"
            placeholder="C/Marosa, Gurabo Arriba, Santiago"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
              type={'number'}
            value={model.residentialQty}
            name="residentialQty"
            onChange={onChange}
            fontWeight="bold"
            label="Cantidad de Apartamentos"
            placeholder="24"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
              onChange={(e, value) =>
                  onChange(e, { value:value, autocomplete: "billingDay" })
              }
              getOptionLabel={(option) => option.toString()}
              options={billingDayData}
              renderInput={(params) => (
                  <FormField {...params} label="Día de corte" InputLabelProps={{ shrink: true }} />
              )}
              renderOption={(props, option) => {
                return (
                    <li {...props} key={option}>
                      {option}
                    </li>
                );
              }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
              type={'number'}
              value={model.maintenanceCost}
              name="maintenanceCost"
              onChange={onChange}
              fontWeight="bold"
              label="Costo por mantenimiento"
              placeholder="0"
          />
        </Grid>
        <br />
        <MDButton disabled={isDisabled} style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Crear
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
