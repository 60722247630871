import React from "react";
import { useFirebase } from "react-redux-firebase";
import { TableActions } from "./components/Table.Actions";
import {AlertModel, ExpensesModel, FiltersModel, PayrollModel} from "../../model";
import {DateTime} from "luxon";

const useData = () => {
  const firebase = useFirebase();
  const [model, setModel] = React.useState(new PayrollModel());
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [residential, setResidential] = React.useState([]);
  const [payroll, setPayroll] = React.useState([]);
  const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
  const [filtersData, setFiltersData] = React.useState(null);

  React.useEffect(() => {
    const db = firebase.firestore();
    db.collection("payroll")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const list = [];
        docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setPayroll(list);
      });
    db.collection("residential")
      .orderBy("residential", "asc")
      .get()
      .then((docs) => {
        const list = [];
        docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.label = data.residential;
          list.push(data);
        });
        setResidential(list);
      });
  }, []);

  const actions = {
    onEdit: (data) => {
      setModel(data);
      setOpenEdit(true);
    },

    onDelete: (data) => {
      setModel(data);
      setOpenDelete(true);
    },
  };

  const onCreate = () => {
    setLoading(true);
    setOpen(false);
    const db = firebase.firestore();
    db.collection("payroll")
      .add({
        createdBy: {
          id: firebase.auth().currentUser.uid,
          displayName: firebase.auth().currentUser.displayName,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        employeesName: model.employeesName,
        employeesTel: model.employeesTel,
        employeesPosition: model.employeesPosition,
        employeesSalary: model.employeesSalary,
        employeesPay: model.employeesPay,
        residential: model.residential,
      })
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Empleado Agregado Correctamente" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new PayrollModel());
      });
  };
  const onUpdate = () => {
    setLoading(true);
    setOpenEdit(false);
    const db = firebase.firestore();
    db.collection("payroll")
      .doc(model.id)
      .update({
        updatedBy: {
          id: firebase.auth().currentUser.uid,
          displayName: firebase.auth().currentUser.displayName,
        },
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        employeesName: model.employeesName,
        employeesTel: model.employeesTel,
        employeesPosition: model.employeesPosition,
        employeesSalary: model.employeesSalary,
        employeesPay: model.employeesPay,
        residential: model.residential,
      })
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Datos Del Empleado Actualizados" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new PayrollModel());
      });
  };

  const onDelete = () => {
    setOpenDelete(false);
    const db = firebase.firestore();
    db.collection("payroll")
      .doc(model.id)
      .delete()
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Empleado Eliminado Correctamente" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new PayrollModel());
      });
  };

  const onChange = (e, select) => {
    if (!select) {
      const { name, value } = e.target;
      setModel((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const selected = { ...select };
      const name = selected.autocomplete;
      delete selected.autocomplete;
      setModel((prevState) => ({ ...prevState, [name]: selected }));
    }
  };


  const filtersOnchange = (e, select) => {
    if (!select) {
      const {name, value} = e.target;
      setFiltersValues((prevState) => ({...prevState, [name]: value}));
    } else {
      const selected = {...select};
      const name = selected.autocomplete;
      delete selected.autocomplete;
      setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
    }
  }

  const onFilters = () => {
    const db = firebase.firestore();
    db.collection("payroll")
        .where('residential.id', '==', filtersValues.residential.id)
        .get()
        .then((docs) => {
          const list = [];
          docs.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            list.push(data);
          });
          setFiltersData(list);
        });
  }

  const payrollList = React.useMemo(() => {
    return (filtersData? filtersData : payroll).map((data) => ({
      ...data,
      id: data.id,
      createdAt: data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
      actions: <TableActions data={data} {...actions} />,
    }));

  }, [filtersData, payroll])

  return {
    residential,
    payrollList,
    model,
    setModel,
    openDelete,
    openEdit,
    open,
    setOpen,
    setOpenDelete,
    setOpenEdit,
    cAlert,
    setCAlert,
    loading,
    onChange,
    onCreate,
    onUpdate,
    onDelete,
    filtersValues,
    filtersOnchange,
    onFilters,
  };
};

export default useData;
