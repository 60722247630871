import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {jsPDF} from "jspdf";
import logo from "../../../assets/logo.png";
import {DateTime} from "luxon";

export function TableActions({data, onEdit, onDelete}) {


    var formatter = new Intl.NumberFormat('es-do', {
        style: 'currency',
        currency: 'DOP',
    });

    function PdfIncome(data) {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            floatPrecision: 16,
            compress: true,
        });

        doc.addImage(logo, "JPEG", 15, 40, 40, 40);
        doc.text('Fecha: ' + DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy'), 15, 90);
        doc.text('Teléfono: (800) 200-300', 15, 100);

        doc.text('Cliente: ' + data.tenant.label, 190, 80, null, null, "right");
        doc.text('Residencial: ' + data.residential.label, 190, 90, null, null, "right");
        doc.text('Apartamento: ' + data.apartment.label, 190, 100, null, null, "right");

        doc.text("Concepto: Pago Consumo de Gas", 105, 130, null, null, "center");
        doc.text("Galones de Gas Consumidos " + data.gallonsQty, 105, 140, null, null, "center");
        doc.text("Precio Por Galón " + data.price, 105, 150, null, null, "center");
        doc.text("Total pagado: " + formatter.format(data.amount), 105, 160, null, null, "center");


        doc.save(`Factura-${DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy')}.pdf`);
    }

    return [
        <IconButton
            onClick={() => onEdit(data)}
            key={2}
            style={{marginRight: 2}}
            variant="gradient"
            color="warning"
            size="medium"
        >
            <Icon>edit</Icon>
        </IconButton>,
        <IconButton
            onClick={() => PdfIncome(data)}
            key={4}
            style={{marginRight: 2}}
            variant="gradient"
            color="info"
            size="medium"
        >
            <Icon>print</Icon>
        </IconButton>,
        <IconButton onClick={() => onDelete(data)} key={3} variant="gradient" color="error" size="medium">
            <Icon>delete</Icon>
        </IconButton>,
    ];
}
