import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { useFirebase } from "react-redux-firebase";

export default function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [model, setModel] = React.useState({ email: "", password: "" });
  const [loading, setLoading] = React.useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const firebase = useFirebase();

  const onChange = (e) => {
    const { name, value } = e.target;
    setModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const onUserLogin = () => {
    if (model.email !== "" && model.password !== "") {
      setLoading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(model.email, model.password)
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  return (
    <IllustrationLayout
      title="Inicio de sesion"
      description="Ingrese su correo electrónico y contraseña para iniciar sesión"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={model.email}
            type="email"
            label="Email"
            name="email"
            fullWidth
            onChange={onChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={model.password}
            onChange={onChange}
            name="password"
            type="password"
            label="Password"
            fullWidth
          />
        </MDBox>
        {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
        {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
        {/*  <MDTypography*/}
        {/*    variant="button"*/}
        {/*    fontWeight="regular"*/}
        {/*    color="text"*/}
        {/*    onClick={handleSetRememberMe}*/}
        {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
        {/*  >*/}
        {/*    &nbsp;&nbsp;Remember me*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
        <MDBox mt={4} mb={1}>
          <MDButton
            loading={loading}
            onClick={onUserLogin}
            variant="gradient"
            color="info"
            size="large"
            fullWidth
          >
            Inicio de sesion
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}
