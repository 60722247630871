import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Modal, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import MonthsToPay from "./MonthsToPay";
import {FormLabel} from '@mui/material';
import {DateTime} from "luxon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

var formatter = new Intl.NumberFormat('es-do', {
    style: 'currency',
    currency: 'DOP',
});

export default function AddModal({
                                     open,
                                     handleClose,
                                     onCreate,
                                     model,
                                     onChange,
                                     apartments,
                                     residential,
                                     bank,
                                     monthsList,
                                     onSelectMonth,
                                     numberOfMonths,
                                     dueDate
                                 }) {

    let isDisabled =React.useMemo(()=>{
        return !(model.apartment && model.residential && model.bank && numberOfMonths > 0)
    },[model, numberOfMonths])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    AGREGAR NUEVO INGRESO
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.residential}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, ...value, autocomplete: "residential"})
                        }
                        options={residential}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Residencial" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        value={model.apartment}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, ...value, autocomplete: "apartment"})
                        }
                        options={apartments}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Apartamento" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>


                <Grid item xs={12}>
                    <Autocomplete
                        value={model.bank}
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, autocomplete: "bank"})
                        }
                        options={bank}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Via De Pago" InputLabelProps={{shrink: true}}/>
                        )}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>
                <MonthsToPay handle={onSelectMonth} monthsList={monthsList}/>
                <br/>
                <Grid item xs={12}>
                    <FormLabel style={{fontSize: 14}}>Monto</FormLabel>
                    <h4 style={{color: '#000'}}>{model.amount ? formatter.format(model.amount * numberOfMonths) : 'RD$0.00'}</h4>
                </Grid>
                <br/>

                {dueDate? (
                   <>
                       <Grid item xs={12}>
                           <FormLabel style={{fontSize: 14}}>Fecha de vencimiento</FormLabel>
                           <h4 style={{color: '#000'}}>{DateTime.fromJSDate(dueDate).toFormat('DD')}</h4>
                       </Grid>
                       <br/>
                   </>
                ):null}

                <Grid item xs={12}>
                    <FormField
                        value={model.comment}
                        name="comment"
                        onChange={onChange}
                        fontWeight="bold"
                        label="Comentario"
                        placeholder="pago Recibido"
                    />
                </Grid>
                <br/>
                <MDButton disabled={isDisabled} style={{marginRight: 5}} color="success" onClick={onCreate}>
                    Crear
                </MDButton>
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
