import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, BalanceModel} from "../../model";
import {DateTime} from "luxon";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new BalanceModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [balance, setbalance] = React.useState([]);
    const [residential, setResidential] = React.useState([]);

    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("balance")
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setbalance(list);
            });
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });
    }, []);

    const actions = {
        onEdit: (data) => {
            setModel(data);
            setOpenEdit(true);
        },

        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
    };

    const onCreate = () => {
        setLoading(true);
        setOpen(false);
        const db = firebase.firestore();
        db.collection("balance")
            .doc(model.residential.id)
            .set({
                createdBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                residential: model.residential,
                balance: model.balance,
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Balance Inicial Agregado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new BalanceModel());
            });
    };


    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setModel((prevState) => ({...prevState, [name]: selected}));
        }
    };

    const balanceList = balance.map((data) => ({
        ...data,
        id: data.id,
        createdAt: data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
        actions: <TableActions data={data} {...actions} />,
    }));

    return {
        residential,
        balanceList,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
    };
};

export default useData;
