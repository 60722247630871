import React from 'react'
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function ChangePassword({
                            onChangePassword,
                            currentPassword,
                            password,
                            confirmPassword,
                            setCurrentPassword,
                            setPassword,
                            setConfirmPassword
                        }) {


    const isDisabled = React.useMemo(() => {
        return !(currentPassword.length > 0 && password.length >= 6 && (password === confirmPassword))
    }, [currentPassword, password, confirmPassword])


    return (
        <Card id="change-password">
            <MDBox p={3}>
                <MDTypography variant="h5">Cambiar Contraseña</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MDInput
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            fullWidth
                            label="Contraseña Actual"
                            inputProps={{type: "password", autoComplete: ""}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            label="Nueva Contraseña"
                            inputProps={{type: "password", autoComplete: ""}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            fullWidth
                            label="Confirmar Contraseña"
                            inputProps={{type: "password", autoComplete: ""}}
                        />
                    </Grid>
                </Grid>
                <MDTypography variant="button" color="text" fontWeight="medium">
                    Las contraseñas debe contener un mínimo de 6 caracteres
                </MDTypography>
                <MDBox ml="auto">
                    <br/>
                    <MDButton disabled={isDisabled} onClick={onChangePassword} variant="gradient" color="info">
                        Actualizar Contraseña
                    </MDButton>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default ChangePassword;
