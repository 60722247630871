import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import RecordPaymentModal from "./components/RecordPaymentModal";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";
import Filters from "./Filters";

export default function Apartments() {
    const {
        apartmentsList,
        tenants,
        residential,
        setModel,
        cAlert,
        setCAlert,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        model,
        loading,
        filtersValues,
        filtersOnchange,
        onFilters,
        recordPaymentsList,
        modalRecordPayments,
        setModalRecordPayments
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Filters residential={residential} values={filtersValues} onChange={filtersOnchange} onClick={onFilters} />
            <MDBox mt={4}>
                <DataTable
                    canSearch={true}
                    onClick={() => setOpen(true)}
                    table={{
                        columns: [
                            {Header: "apt.", accessor: "apartment"},
                            {Header: "residencial", accessor: "residential.label"},
                            {Header: "propietario", accessor: "tenant.label"},
                            {Header: "Estado de pago", accessor: "paymentStatus"},
                            {Header: "Meses pendientes", accessor: "monthsPending"},
                            {Header: "Deuda anterior", accessor: "previousDueAmount"},
                            {Header: "Fecha de vencimiento", accessor: "dueDate"},
                            {accessor: "actions"},
                        ],
                        rows: apartmentsList,
                    }}
                />
            </MDBox>

            {open && (
                <AddModal
                    tenants={tenants}
                    residential={residential}
                    onChange={onChange}
                    onCreate={onCreate}
                    open={open}
                    handleClose={() => setOpen(false)}
                    model={model}
                    setModel={setModel}
                />
            )}

            {openEdit && (
                <EditModal
                    tenants={tenants}
                    residential={residential}
                    onChange={onChange}
                    onCreate={onUpdate}
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    model={model}
                    setModel={setModel}
                />
            )}

            <DeleteModal onDelete={onDelete} open={openDelete} handleClose={() => setOpenDelete(false)}/>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <RecordPaymentModal data={recordPaymentsList} handleClose={()=>setModalRecordPayments(false)} open={modalRecordPayments}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}
