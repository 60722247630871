import Profile from "views/profile";
import Setup from "views/setup";
import Reports from "views/reports";
import Residential from "views/residential";
import Apartments from "views/apartments";
import RegistroContable from "views/registroContable";
import Tenants from "views/tenants";
import Income from "views/income";
import Due from "views/due";
import OthersIncome from "views/othersIncome";
import Bank from "views/bank";
import Expenses from "views/expenses";
import Providerpay from "views/provider";
import Payroll from "views/payroll";
import Inventory from "views/inventory";
import Gas from "views/gas";
import Balance from 'views/balance';
import Icon from "@mui/material/Icon";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Dashboard from "views/dashboard";
import Rules from "views/ruler";

const routes = [
  {
    type: "navLink",
    name: "Dashboards",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Administracion",
    key: "Administracion",
    icon: <Icon fontSize="medium">apartment</Icon>,
    collapse: [
      {
        name: "Residenciales",
        key: "residential",
        route: "/administracion/residential",
        component: <Residential />,
      },
      {
        name: "Apartamentos",
        key: "apartments",
        route: "/administracion/apartments",
        component: <Apartments />,
      },
      {
        name: "Propietarios",
        key: "tenants",
        route: "/administracion/tenants",
        component: <Tenants />,
      },
      {
        name: "Inventario",
        key: "inventory",
        route: "/administracion/inventory",
        component: <Inventory />,
      },
      {
        name: "Balance",
        key: "balance",
        route: "/administracion/balance",
        component: <Balance />,
      },
      {
        name: "Normas",
        key: "rules",
        route: "/administracion/rules",
        component: <Rules />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Contabilidad",
    key: "contabilidad",
    icon: <PointOfSaleTwoToneIcon fontSize="medium" />,
    collapse: [
      {
        name: "Deuda anterior",
        key: "due",
        route: "/contabilidad/deuda-anterior",
        component: <Due />,
      },
      {
        name: "Ingresos",
        key: "income",
        route: "/contabilidad/income",
        component: <Income />,
      },
      {
        name: "Otros Ingresos",
        key: "otros-ingresos",
        route: "/contabilidad/otros-ingresos",
        component: <OthersIncome />,
      },
      {
        name: "Consumo de Gas",
        key: "gas",
        route: "/contabilidad/gas",
        component: <Gas />,
      },
      {
        name: "Gastos",
        key: "expenses",
        route: "/contabilidad/expenses",
        component: <Expenses />,
      },
      {
        name: "Registro Contable",
        key: "registro-contable",
        route: "/contabilidad/registro-contable",
        component: <RegistroContable />,
      },
      {
        name: "Cuentas Bancarias",
        key: "cuentas-bancarias",
        route: "/contabilidad/cuentas-bancarias",
        component: <Bank />,
      },
      {
        name: "Nomina",
        key: "payroll",
        route: "/contabilidad/payroll",
        component: <Payroll />,
      },
    ],
  },
  {
    type: "navLink",
    name: "Proveedores",
    key: "provider",
    route: "/provider",
    icon: <HomeRepairServiceIcon fontSize="medium" />,
    component: <Providerpay />,
  },
  {
    type: "navLink",
    name: "Perfil",
    key: "perfil",
    route: "/perfil",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    component: <Profile />,
  },

  // {
  //   type: "navLink",
  //   name: "Reportes",
  //   key: "reportes",
  //   route: "/reportes",
  //   icon: <Icon fontSize="medium">bar_chart</Icon>,
  //   component: <Reports />,
  // },
  // {
  //   type: "navLink",
  //   name: "Configuracion",
  //   key: "Configuracion",
  //   route: "/configuracion",
  //   icon: <Icon fontSize="medium">tune</Icon>,
  //   component: <Setup />,
  // },
  // {
  //   type: "navLink",
  //   name: "Perfil",
  //   key: "perfil",
  //   route: "/perfil",
  //   icon: <Icon fontSize="medium">manage_accounts</Icon>,
  //   component: <Profile />,
  // },
];

export default routes;
