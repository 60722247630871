import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Modal, Typography} from "@mui/material";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

export default function SendMailModal({
                                     open,
                                     handleClose,
                                     onCreate,
                                     model,
                                     onChange,
                                 }) {

    let isDisabled = React.useMemo(() => {
        return !(model.apartment)
    }, [model])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    Envio de factura por correo
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <FormField
                        value={model.mail}
                        name="mail"
                        onChange={onChange}
                        fontWeight="bold"
                        label="Correo electrónico"
                        placeholder="Correo electrónico"
                    />

                </Grid>
                <br/>
                <MDButton disabled={isDisabled} style={{marginRight: 5}} color="success" onClick={onCreate}>
                    Enviar
                </MDButton>
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
