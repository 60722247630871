import axios from "axios";
import { firebase } from ".";
import {baseURL} from "../config";

const instance = axios.create({
  baseURL: baseURL,
  headers: { Accept: "application/json" },
});

const injectUserAuthToken = (config) =>
  firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      config.headers.Authorization = `Bearer ${token}`;
      return Promise.resolve(config);
    })
    .catch((e) => Promise.resolve(config));

instance.interceptors.request.use(injectUserAuthToken, (error) => Promise.reject(error));

export default instance;
